<template>
	<v-app>
		<v-main>
			<v-container class="fill-height" fluid style="background:url('./img/bck_skoda_4.png'); background-size: cover;">
				<v-row align="center" justify="center">
					<v-col cols="12" sm="8" md="4" lg="3" xl="2">
						<v-card elevation="12">
							<v-toolbar :color="appSetup.appColor" dense dark flat>
								<v-toolbar-title>{{ appSetup.appName }} login</v-toolbar-title>
								<div class="flex-grow-1" />
							</v-toolbar>
							<v-card-text>
								<v-form>
									<v-text-field v-model="user.Username" label="Username" name="login" prepend-icon="mdi-account" type="text" :autofocus="true" :rules="[rules.required]" @keyup.enter="login" />
									<v-text-field id="Password" v-model="user.Password" label="Password" name="Password" prepend-icon="mdi-lock" :append-icon="PasswordVisible ? 'mdi-eye' : 'mdi-eye-off'" :type="PasswordVisible ? 'text' : 'Password'" :rules="[rules.required]" @keyup.enter="login" @click:append="PasswordVisible = !PasswordVisible" />
								</v-form>
							</v-card-text>
							<v-card-actions>
								<div class="flex-grow-1" />
								<v-btn outlined color="primary" @click="login">
									Login
								</v-btn>
							</v-card-actions>
							<v-card-actions v-if="error">
								<v-row>
									<v-col cols="12">
										<v-alert text class="mb-0" type="error">
											{{ errorText }}
										</v-alert>
									</v-col>
								</v-row>
							</v-card-actions>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</v-main>
		<loading :loading="loading" />
	</v-app>
</template>

<script>
import Loading from '@/components/loading'
export default {
	name: 'Login',
	components: { Loading },
	props: {
		dark: Boolean
	},
	data: function () {
		return {
			user: {
				Username: '',
				Password: ''
			},
			PasswordVisible: false,
			loading: false,
			error: false,
			errorText: '',
			rules: {
				required: value => !!value || 'Campo obbligatorio.',
				min: v => v.length >= 8 || 'Almeno 8 caratteri'
			},
		}
	},
	computed: {
		appSetup() { return window.setup },
		isLoggedIn() { return this.$store.getters['isLoggedIn'] }
	},
	created: function () {
		if (this.appSetup.isDevelop) {
			// solo per lo sviluppo presetta l'account per comodità
			// this.user.Username = 'TOM00'
			// this.user.Password = 'T0m1ta_Sk0da'
			//
			// -- utente "organizzato" --
			// this.user.Username = '00001'
			// this.user.Password = 'SW415G8'
			// -- utente "superuser" --
			// this.user.Username = 'SKOD1'
			// this.user.Password = 'skoda'
			// -- utente "admin" --
			this.user.Username = 'SUPAD'
			this.user.Password = 'Studiumgr33n'
			this.PasswordVisible = true // questo serve più che altro per evitare l'intervento di autocompilazione del browser
		}
	},
	methods: {
		login() {
			this.error = false
			this.loading = true
			this.$store.dispatch('login', this.user).then(() => {
				this.error = false
				this.loading = false
			}).catch((err) => {
				console.debug(err)
				this.error = true
				setTimeout(() => (this.loading = false), 200)
				if (err.message.indexOf('400') > 0 ) {
					if(err.response && err.response.data && err.response.data.Message && err.response.data.Message === 'Suspended')
						this.errorText = 'Account sospeso'
					else
						this.errorText = 'Username o Password errati'
				} else 
					this.errorText = err.message
			})
		}
	}
}
</script>
